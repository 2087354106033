import type { FormControlValue } from "../_model";
import type { IFormControl } from "solid-forms";

export function fieldIsRequired<V extends FormControlValue>(control: IFormControl<V>) {
  return {
    checkValue() {
      const value = control.value;
      const is_undefined =
        value === undefined ||
        value === null ||
        value === "" ||
        (typeof value === "number" && Number.isNaN(value)) ||
        (typeof value === "boolean" && value === false) ||
        (value as string[])?.length <= 0;
      // console.log(control.id, " ::: ", control.value, " ::: ", is_undefined);

      return is_undefined;
    },
    checkControl() {
      if (!control.isRequired) return undefined;
      const value = control.value;
      const is_empty = this.checkValue();

      if (is_empty) {
        return { required: true };
      } else {
        return undefined;
      }
    },
  };
}
